'use strict'

//Slider
import Swiper from 'swiper/bundle';
import { Navigation, Pagination } from 'swiper';

imageSlider(".js-fade-slider01");
imageSlider(".js-fade-slider02");
imageSlider(".js-fade-slider03");

function imageSlider(slideClass){
  const swiper = new Swiper(slideClass, {
    effect: 'fade',
    fadeEffect: {
      crossFade: true,
    },
    loop: true,
    loopAdditionalSlides: 1,
    speed: 2000,
    autoplay: {
      delay: 4000,
      disableOnInteraction: false,
      waitForTransition: false,
    },
    followFinger: false,

    pagination: {
      el: ".rcm--mdl__ft__clt",
      type: "fraction"
    },
    navigation: {
      prevEl: ".rcm--mdl__ft__btn._r",
      nextEl: ".rcm--mdl__ft__btn._l"
    }
  });
};

//TAB
document.addEventListener('DOMContentLoaded', () => {
  function tabClick(e) {
    
    const tabTargetData = e.currentTarget.dataset.tab
    const tabList = e.currentTarget.closest('.rcm--tab__list');
    const tabItems = tabList.querySelectorAll('._tab');
    const tabContentItems = tabList.nextElementSibling.querySelectorAll('.rcm--tab__in');
    tabItems.forEach((tabItem) => {
      tabItem.classList.remove('is-act');
    });
    tabContentItems.forEach((tabContentItem) => {
      tabContentItem.classList.remove('is-act');
    });
    e.currentTarget.classList.add('is-act');
    tabContentItems.forEach((tabContentItem) => {
      if (tabContentItem.dataset.content === tabTargetData) {
        tabContentItem.classList.add('is-act');
      }
    });
  }
  const tabs = document.querySelectorAll('._tab');
  tabs.forEach((tab) => {
    tab.addEventListener('click', tabClick);
  });
})

//file control
const inputfiles = document.querySelectorAll('.file');
const deletefiles = document.querySelectorAll('.mwform-file-delete');
inputfiles.forEach((files) => {
  files.addEventListener('change', fileuploader);
});
deletefiles.forEach((setDelete) => {
  setDelete.addEventListener('click', filedelete);
});
function fileuploader(e) {
  const inputParent = e.currentTarget.parentElement;
  const inputText = inputParent.querySelectorAll('.js-upload-filename');
  inputText[0].textContent="アップロード済み";
  inputParent.classList.add('is-setfile');
}
function filedelete(e) {
  const inputParent = e.currentTarget.parentElement;
  const labelbox = inputParent.querySelectorAll('.rcm--ctprts__one__file__label');
  const inputText = inputParent.querySelectorAll('.js-upload-filename');
  inputText[0].textContent="ファイルを選択";
  labelbox[0].classList.remove('is-setfile');
}

//Interview filter
const filterType = document.querySelectorAll('.iv--ml__filter__select');
filterType.forEach((filter) => {
  filter.addEventListener('click', filterSet);
});

function filterSet(e) {
  const hasClass = e.currentTarget.classList.contains('is-open');
  //console.log(hasClass);
  if(hasClass){
    e.currentTarget.classList.remove('is-open');
  }else{
    e.currentTarget.classList.add('is-open');
  }
}

const selectLabel = document.querySelectorAll('.iv--ml__filter__dd__lab');
const selectdata = document.querySelectorAll('.iv--ml__filter__dd');
const filterButtons = document.querySelectorAll('.iv--ml__filter__btn__tgt');

var filterAry = new Array();
filterAry = [];
selectLabel.forEach((label) => {
  label.addEventListener('click', labelSet);
});
function labelSet(e) {
  const filterTargetData = e.currentTarget.dataset.filter;
  const choiceTargetData = e.currentTarget.dataset.choice;
  const choiceParent = e.currentTarget.parentElement;
  const choiceSelect = choiceParent.querySelectorAll('.select');
  const inputSet = choiceParent.querySelectorAll('.js-input-set');
  choiceSelect[0].dataset.select = filterTargetData;
  choiceSelect[0].textContent= choiceTargetData;
  inputSet[0].value = filterTargetData;
  //console.log(inputSet);
}


// SORT CONTROL
const selectContents = document.querySelectorAll('[data-select]');
filterButtons.forEach((filterButton) => {
  filterButton.addEventListener('click', sort);
});

const jobType = document.getElementById('jobType');
const intention = document.getElementById('intention');
const career = document.getElementById('career');
function sort() {
    var jobValue = jobType.value;
    var intentionValue = intention.value;
    var careerValue = career.value;
    //console.log("jobValue：" + jobValue + "/intentionValue：" + intentionValue + "/careerValue：" + careerValue);
    const targetContents = document.querySelectorAll('[data-category]');
    targetContents.forEach((categoryContent) => {
      categoryContent.animate([
        { opacity: 0 },
        { opacity: 1 }
      ],
        { duration: 600, fill: 'forwards' }
      );
      categoryContent.classList.remove("is-show");
    });
    if (jobValue == 'all') {
        jobValue = 'iv--ml__list__card';
    }
    if (intentionValue == 'all') {
        intentionValue = 'iv--ml__list__card';
    }
    if (careerValue == 'all') {
        careerValue = 'iv--ml__list__card';
    }
    targetContents.forEach((filterContent) => {
      var jobSelect = filterContent.classList.contains(jobValue);
      var intentionSelect = filterContent.classList.contains(intentionValue);
      var careerSelect = filterContent.classList.contains(careerValue);
      //console.log(jobSelect);
      if (jobSelect && intentionSelect && careerSelect) {
        filterContent.classList.add('is-show');
      }else{
        filterContent.classList.remove("is-show");
      }
      //console.log(jobValue, intentionValue, careerValue);
    });
};